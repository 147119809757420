var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[(_vm.shipment.originalLocation)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"subtitle-1 black--text"},[_vm._v(_vm._s(_vm.$_strings.order.LOCATION_MAP))])]),_c('v-col',[_c('GmapMap',{staticStyle:{"width":"100%","height":"400px"},attrs:{"center":_vm.center,"zoom":5,"map-type-id":"terrain"}},[_c('GmapMarker',{attrs:{"position":{
            lat: _vm.shipment.originalLocation.latitude,
            lng: _vm.shipment.originalLocation.longitude
          },"clickable":true,"icon":{url : require('@/assets/images/map-marker-origin.png')}}}),_c('GmapMarker',{attrs:{"position":{
            lat: _vm.shipment.destinationLocation.latitude,
            lng: _vm.shipment.destinationLocation.longitude
          },"clickable":true,"icon":{url : require('@/assets/images/map-marker-destination.png')}}}),(_vm.shipment.driverLatitude && _vm.shipment.driverLongitude)?_c('section',[_c('GmapMarker',{attrs:{"position":{
              lat: _vm.shipment.driverLatitude,
              lng: _vm.shipment.driverLongitude
            },"clickable":true,"icon":{url : require('@/assets/images/yellow-truck-map-marker-icon.png')}}})],1):_vm._e(),(_vm.shipment.multiPick)?_c('section',_vm._l((_vm.shipment.multiPick),function(multipick,index){return _c('GmapMarker',{key:index,attrs:{"position":{
              lat: multipick.latitude,
              lng: multipick.longitude
            },"clickable":true,"icon":{url : require('@/assets/images/map-marker-multipick.png')}}})}),1):_vm._e(),(_vm.shipment.multiDrop)?_c('section',_vm._l((_vm.shipment.multiDrop),function(multidrop,index){return _c('GmapMarker',{key:index,attrs:{"position":{
              lat: multidrop.latitude,
              lng: multidrop.longitude
            },"clickable":true,"icon":{url : require('@/assets/images/map-marker-multidrop.png')}}})}),1):_vm._e()],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }