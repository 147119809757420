<template>
  <section>
    <v-row v-if="shipment.originalLocation">
      <v-col cols="12">
        <p class="subtitle-1 black--text">{{$_strings.order.LOCATION_MAP}}</p>
      </v-col>
      <v-col>
        <GmapMap
          :center='center'
          :zoom='5'
          map-type-id="terrain"
          style='width:100%;  height: 400px;'
        >
          <!-- lokasi asal -->
          <GmapMarker
            :position="{
              lat: shipment.originalLocation.latitude,
              lng: shipment.originalLocation.longitude
            }"
            :clickable="true"
            :icon="{url : require('@/assets/images/map-marker-origin.png')}"
          />
          <!-- lokasi tujuan-->
          <GmapMarker
            :position="{
              lat: shipment.destinationLocation.latitude,
              lng: shipment.destinationLocation.longitude
            }"
            :clickable="true"
            :icon="{url : require('@/assets/images/map-marker-destination.png')}"
          />
          <!-- lokasi driver-->
          <section v-if="shipment.driverLatitude && shipment.driverLongitude">
            <GmapMarker
              :position="{
                lat: shipment.driverLatitude,
                lng: shipment.driverLongitude
              }"
              :clickable="true"
              :icon="{url : require('@/assets/images/yellow-truck-map-marker-icon.png')}"
            />
          </section>
          <!-- multipick -->
          <section v-if="shipment.multiPick">
            <GmapMarker
              v-for="(multipick, index) in shipment.multiPick" :key="index"
              :position="{
                lat: multipick.latitude,
                lng: multipick.longitude
              }"
              :clickable="true"
              :icon="{url : require('@/assets/images/map-marker-multipick.png')}"
            />
          </section>
          <!-- multidrop -->
          <section v-if="shipment.multiDrop">
            <GmapMarker
              v-for="(multidrop, index) in shipment.multiDrop" :key="index"
              :position="{
                lat: multidrop.latitude,
                lng: multidrop.longitude
              }"
              :clickable="true"
              :icon="{url : require('@/assets/images/map-marker-multidrop.png')}"
            />
          </section>
        </GmapMap>
      </v-col>
    </v-row>
  </section>
</template>

<script>
export default {
  props: {
    shipment: {
      type: Object,
      default: () => {},
    },
    driverLatitude: {
      default: null,
    },
    driverLongitude: {
      default: null,
    },
  },
  mounted() {
    this.geolocate();
  },
  data() {
    return {
      center: {
        lat: -6.1753917,
        lng: 106.82715329999996,
      },
    };
  },
  methods: {
    geolocate() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
      });
    },
  },
};
</script>
